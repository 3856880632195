import React, {useState} from "react";

import {Loader, MaxBtn} from "../core/components";
import {CheckboxInput, DateTimeInput, EditorInput, EnumInput, InputContainer, LightContainer, SelectfieldInput, TagsInput, TextareaInput, TextfieldInput} from "../core/input_fields";
import {FormContextWrapper} from "../core/form_context";
import {maxiPost} from "../core/maxios";
import Status from "../core/status";
import "./style.sass"
import UserHistory from "../user/userHistory";
import {EventKind, EventKindMap, EventStatus, SectorType, SectorTypeLicence, LicenceKind, SectorTypeMap} from "../core/enums";
import Consts from "../core/consts";

const fields = {
    name: "Name",
    //status: "Status",
    eventStart: "Veranstaltungs-Start",
    eventEnd: "Veranstaltungs-Ende",
    bookingStart: "Buchbar ab",
    bookingEnd: "Buchbar bis",
    showStart: "Anzeigen ab",
    prebookingEnd: "Frühbuchungs-Ende",
    allowOfficials: "Offizielle erlauben",
    teamsRequired: "Anmeldung als Mannschaft",
    maxParts: "maximale Teilnehmeranzahl",
    city: "Ort",
    eventNoExt: "Veranstaltungs-Nr.",
    minPermission: "Berechtigung",
    emailAddressesRequired: "E-Mail Adressen erforderlich",
    kind: "Veranstaltungsart",
    sector: "von Sparte",
    forSectors: "für Sparten",
    forLicence: "Für Lizenz",
    //lessons: "Unterrichtseinheiten",
    "titleForCertificate": "Bezeichnung auf Bestätigung",
    focus: "Schwerpunkte",
    "descriptionForCertificate": "Text auf Bestätigung",
    description: "Beschreibung",
};


export default function EventEdit({event, reload}) {

    const [{error, loading, success}, setStatusVar] = useState({});
    const [state, setState] = useState({event})
    const handleSubmit = () => {
        if (loading) {
            return
        }
        setStatusVar({loading: true})
        maxiPost("/event", state.event, {setStatusVar})
            .then((resp) => {
                if (!event.ID) {
                    setState(old => ({event: {...old.event, ID: resp.event_ID}}))
                } else {
                    reload()
                }
            })
    }
    if (event.ID !== state.event.ID && !!state.event.ID) {
        return window.location.href = `/veranstaltungen/${state.event.ID}`
    }
    return <LightContainer name={event.ID && "Veranstaltung bearbeiten"}>
        <FormContextWrapper value={{state, setState}} onSubmit={handleSubmit}>
            <Status type={"error"} text={error}/>
            {Object.entries(fields).map(([f, fieldName], i) => {
                switch (f) {
                    case "description":
                    case "information":
                        return <EditorInput name={fieldName} key={"event_" + f} tag={"event_" + f} style={{width: "100%"}}/>;
                    case "allowOfficials":
                    case "emailAddressesRequired":
                        return <InputContainer>
                            <CheckboxInput name={fieldName} tag={"event_" + f}/>
                            {i % 2 === 0 && <br/>}
                        </InputContainer>;
                    case "teamsRequired":
                        return <InputContainer>
                            <SelectfieldInput name={fieldName} selectives={[
                                ["0", "Nur als Einzelpersonen"],
                                ["1", "Nur als Teams"],
                                ["2", "beides"],
                            ]} tag={"event_" + f}/>
                            {i % 2 === 0 && <br/>}
                        </InputContainer>;
                    case "sector":
                    case "forSectors":
                    case "kind":
                        if (f === "forSectors" && ![EventKindMap.fortbildung, EventKindMap.ausbildung, EventKindMap.ausbildungwr].includes(state.event.kind?.value)) {
                            return null;
                        }
                        return <InputContainer>
                            <EnumInput name={fieldName} tag={"event_" + f} type="reactselect" selectives={{sector: SectorType.filter(a => a.value !== SectorTypeMap.t10 && a.value !== SectorTypeMap.tfa), forSectors: SectorTypeLicence, kind: EventKind}[f]} multiple={{forSectors: true}[f] ?? false}/>
                            {i % 2 === 0 && <br/>}
                        </InputContainer>;

                    case "forLicence":
                        if (![EventKindMap.fortbildung, EventKindMap.ausbildung, EventKindMap.ausbildungwr].includes(state.event.kind?.value)) {
                            return null;
                        }
                        return <InputContainer>
                            <EnumInput name={fieldName} tag={"event_" + f} multiple type="reactselect" selectives={LicenceKind}/>
                            <br/>
                        </InputContainer>;
                    case "minPermission":
                        return <InputContainer>
                            <SelectfieldInput name={"Berechtigung"} tag={"event_" + f} selectives={Object.entries(Consts.permissionMap)}/>
                        </InputContainer>

                    case "status":
                        return <InputContainer>
                            <EnumInput name={fieldName} tag={"event_" + f} type="reactselect" selectives={EventStatus}/>
                            {i % 2 === 0 && <br/>}
                        </InputContainer>;
                    case "descriptionForCertificate":
                        return (state.event.descriptionForCertificate?.length || 0) < 30 ?
                            <InputContainer>
                                <TextfieldInput name={fieldName} tag={"event_" + f}/>
                                {i % 2 === 0 && <br/>}
                            </InputContainer> :
                            <div>
                                <TextareaInput name={fieldName} tag={"event_" + f}/>
                                <br/>
                            </div>


                    case "name":
                        return <>
                            <TextfieldInput class={"form-control "} width={500} name={fieldName} tag={"event_" + f}/>
                            <br/>
                        </>;
                    default:

                        if (f.endsWith("Start") || f.endsWith("End")) {
                            return <InputContainer>
                                <DateTimeInput name={fieldName}
                                               {...{[f.endsWith("Start") ? "maxDate" : "minDate"]: state.event[f.endsWith("Start") ? f.replace("Start", "End") : f.replace("End", "Start")]}}
                                               midNight tag={"event_" + f}/>
                                {i % 2 === 0 && <br/>}
                            </InputContainer>;
                        }
                        return <InputContainer>
                            <TextfieldInput class={"form-control "} name={fieldName} tag={"event_" + f}/>
                            {i % 2 === 0 && <br/>}
                        </InputContainer>;
                }

            })}
            <MaxBtn>Speichern</MaxBtn><Loader loading={loading}/>
        </FormContextWrapper>
        {
            event.ID &&
            <MaxBtn onClick={() => window.confirm("Wirklich löschen?") && maxiPost("/event/delete", {ID: event.ID}, {setStatusVar}).then(() => window.location.href = "/")}>Löschen</MaxBtn>
        }

        {
            event.ID && <LightContainer name={"Veränderungen"}>
                <UserHistory userID={event.ID} personType={"event"}/>
            </LightContainer>
        }
    </LightContainer>

}
